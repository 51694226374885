import Head from "next/head";
import { useEffect } from "react";
import { useRouter } from "next/router";

const Custom404: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace("/");
  }, [router]);

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <div></div>
    </>
  );
};

export default Custom404;